<!--
 * @Author: dongjia
 * @Date: 2021-08-16 15:10:59
 * @LastEditTime: 2021-10-28 11:50:21
 * @LastEditors: aleaner
 * @Description: 帖子详情页
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\post-admin\detail.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="detail-page" v-loading="loading">
    <div class="page-header" v-if="detail">
      <div class="user-info">
        <el-image
          class="user-avatar"
          :src="detail.author_avatar || defaultAvatar"
          fit="cover"
        ></el-image>
        <div class="user-content">
          <div class="user-name">
            <div class="name">{{ detail.author_name }}</div>
            <div class="black-tag" v-if="detail.black_status">黑名单</div>
          </div>
          <div class="post-time">{{ detail.create_time }}</div>
        </div>
      </div>
      <div
        v-if="!['GovTopicPostDetail', 'GovPostDetail'].includes(routeName)"
        class="detail-handler"
      >
        <el-dropdown trigger="click" @command="handleClickRow">
          <div class="el-dropdown-link">
            内容管理<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu v-if="detail.status === 1" slot="dropdown">
            <el-dropdown-item command="handleEdit">编辑内容</el-dropdown-item>
            <!-- <el-dropdown-item command="handleRecommend"
              >{{ detail.is_recommend ? '取消设为推荐' : '设为推荐' }}
            </el-dropdown-item> -->
            <!-- <el-dropdown-item command="handleShow">{{
              detail.is_show ? '隐藏内容' : '显示内容'
            }}</el-dropdown-item> -->
            <!-- <el-dropdown-item command="handleBlack" v-if="!detail.is_admin">
              {{detail.black_status?'恢复白名单':'加入黑名单'}}</el-dropdown-item> -->
            <el-dropdown-item command="handleReply">快速回答</el-dropdown-item>
          </el-dropdown-menu>
          <el-dropdown-menu v-else slot="dropdown">
            <el-dropdown-item command="handleEdit">编辑内容</el-dropdown-item>
            <el-dropdown-item command="handleChecked"
              >通过审核</el-dropdown-item
            >
            <el-dropdown-item command="handleNoCheck" v-if="detail.status !== 2"
              >不通过审核
            </el-dropdown-item>
            <!-- <el-dropdown-item command="handleBlack" v-if="!detail.is_admin">
              {{detail.black_status?'恢复白名单':'加入黑名单'}}</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="page-content" v-if="detail">
      <div style="white-space: pre-line">{{ detail.content }}</div>
      <div style="white-space: pre-line" class="page-desc">
        {{ detail.description }}
      </div>
      <!-- popover图片 -->
      <div v-if="detail.images && detail.images.length" class="popover-imgs">
        <el-image
          v-for="(img, index) in detail.images"
          class="popover-img cursor-pointer"
          :src="fixImageUrl(img.url)"
          @click="$previewImage([img.url])"
          fit="cover"
          :key="index"
        >
        </el-image>
      </div>
      <!-- popover视频 -->
      <div
        class="popover-video"
        v-if="detail.video && detail.video.length"
        :style="{
          marginTop: detail.content ? '13px' : '',
        }"
      >
        <el-image
          class="video-thumbnail"
          :src="fixImageUrl(detail.video[0].thumbnail)"
          fit="cover"
        >
        </el-image>
        <div
          class="video-mask cursor-pointer"
          @click="handlePlay(detail.video[0])"
        >
          <img
            class="video-play"
            src="@/base/assets/images/media/video-play@2x.png"
          />
        </div>
      </div>
      <div class="content-number">
        浏览：{{ detail.view_count }} 点赞：{{ detail.like_count }} 评论：{{
          detail.comments_count
        }}
        收藏：{{ detail.like_count }} 分享：{{ detail.share_count }}
      </div>
    </div>
    <div
      class="page-list"
      v-loading="commentListLoading"
      v-if="detail && detail.status === 1"
    >
      <el-table :data="comment_list" class="thead-light" style="width: 100%">
        <el-table-column label="上级评论">
          <template slot-scope="{ row, $index }">
            <div class="level-one-comment">
              {{ row.nickname }}：{{ row.content }}
            </div>
            <div class="comment-handler">
              <div class="comment-time">{{ row.create_time }}</div>
              <div
                v-if="row.level_text == '一级'"
                class="reply-comment cursor-pointer"
                @click="setTopRow(row)"
              >
                {{ row.is_top ? '取消置顶' : '置顶' }}
              </div>
              <div
                class="reply-comment cursor-pointer"
                @click="replyComment(row)"
              >
                回复
              </div>
              <div class="del-comment cursor-pointer" @click="delComment(row)">
                删除
              </div>
            </div>
            <div
              class="level-two-comment"
              v-if="row.replies.length"
              v-loading="loadingMore && row.id === current_comment_id"
            >
              <div
                class="two-level-comment-item"
                v-for="(item, index) in row.replies"
                :key="index"
              >
                <div class="comment-content">
                  {{ item.nickname }}：{{ item.content }}
                </div>
                <div class="handler">
                  <!-- <div class="reply-comment cursor-pointer" @click="replyComment(row)">回复</div> -->
                  <div
                    class="del-comment cursor-pointer"
                    @click="delComment(item)"
                  >
                    删除
                  </div>
                </div>
              </div>
              <div
                class="load-more cursor-pointer"
                v-if="row.replies_count > row.replies.length"
                @click="moreComments(row)"
              >
                查看更多
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />
    </div>
    <VideoDialog />
    <NoCheckedDialog
      v-model="openNoCheckedDialog"
      :Data="noCheckData"
      :updateList="getPostDetail"
    />
    <ReplyDialog
      v-model="openReplyDialog"
      :Data="replyData"
      :commentType="commentType"
      :updateList="refreashCommentList"
    />
    <AddContentDialog
      v-model="openAddContentDialog"
      :Data="addContentData"
      commentType="edit"
      :updateList="refreashCommentList"
    />
    <FixedActionBar>
      <el-button size="medium" @click="cancelConfig">返回</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import {
  postDetail,
  commentList,
  delComment,
  moreComment,
} from '../../api/consult-content-list/detail'
import {
  updateShow,
  updateRecommend,
  check,
  updateBlack,
} from '../../api/consult-content-list/list'
import Pagination from '@/base/components/Default/Pagination'
import VideoDialog from '@/base/components/Preview/VideoViewer'
import NoCheckedDialog from '@/modules/consult/components/ConsultContentList/NoCheckedDialog.vue'
import ReplyDialog from '@/modules/consult/components/ConsultContentList/ReplyDialog.vue'
import AddContentDialog from '@/modules/consult/components/ConsultContentList/AddContentDialog.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import { updateTop } from '@/modules/consult/api/consult-content-comment/list.js'
export default {
  components: {
    Pagination,
    VideoDialog,
    NoCheckedDialog,
    ReplyDialog,
    FixedActionBar,
    AddContentDialog,
  },
  data() {
    return {
      loading: false,
      commentListLoading: false,
      id: '',
      detail: null,
      comment_list: [], // 评论数据
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        '/cyc/images/wxapps/icons/avatar1.png', // 默认头像,
      pageData: {},
      requestData: {
        id: '',
        page_size: 15,
        page: 1,
      },
      noCheckData: {},
      openNoCheckedDialog: false,
      replyData: {},
      openReplyDialog: false,
      commentType: 'comment',

      // 添加问题弹窗
      addContentData: {
        content: '',
        description: '',
        category_id: '',
        video: [],
        images: [],
        article_id: '',
      },
      openAddContentDialog: false,
      current_comment_id: '',
      loadingMore: false,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    editRouterName() {
      if (this.$route.name === 'ActivityPostDetail') {
        // 活动帖子
        return 'ActivityEditPost'
      }
      if (this.$route.params.group_id && this.$route.params.topic_id) {
        return 'editGroupTopicPost'
      } else if (this.$route.params.group_id && !this.$route.params.topic_id) {
        return 'editGroupPost'
      } else if (!this.$route.params.group_id && this.$route.params.topic_id) {
        return 'editTopicPost'
      } else {
        return 'editPost'
      }
    },
    cancelRouteName() {
      if (this.$route.params.group_id && this.$route.params.topic_id) {
        return 'groupTopicDetail'
      } else if (this.$route.params.group_id && !this.$route.params.topic_id) {
        return 'groupPostList'
      } else if (!this.$route.params.group_id && this.$route.params.topic_id) {
        return 'topicDetail'
      } else {
        return 'CirclePostList'
      }
    },
  },
  created() {
    this.id = this.requestData.id = this.$route.params.id
    this.getPostDetail()
    this.getCommentList(this.requestData)
  },
  methods: {
    getPostDetail() {
      this.loading = true
      postDetail({ id: this.id })
        .then((res) => {
          this.detail = res.data
          console.log(res, 'res')
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 帖子详情评论列表
    getCommentList(requestData) {
      this.commentListLoading = true
      commentList(requestData)
        .then((res) => {
          this.comment_list = res.data.data
          this.pageData = res.data
          this.commentListLoading = false
        })
        .catch((err) => {
          this.commentListLoading = false
        })
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit('media/setIsOpenMediaVideoDialog', true)
      this.$store.commit('media/setDialogDetail', data)
    },
    // 分页查询
    changPage(e) {
      this.requestData = { ...this.requestData, ...e }
      this.getPostList(this.requestData)
    },
    // 点击下拉菜单
    handleClickRow(value) {
      this[value]()
    },
    // 编辑内容
    handleEdit() {
      this.addContentData = {
        id: this.detail.id,
        content: this.detail.content,
        category_id: this.detail.category_id || '',
        video: this.detail.video.length ? this.detail.video : [{}],
        images: this.detail.images,
        article_id: this.detail.article_id || '',
        description: this.detail.description || '',
        article_name: this.detail.article.title || '',
      }
      this.openAddContentDialog = true
    },
    // 修改推荐状态
    handleRecommend() {
      let data = {
        id: this.detail.id,
        is_recommend: this.detail.is_recommend === 1 ? 0 : 1,
      }
      this.loading = true
      updateRecommend(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.getPostDetail()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 修改显示状态
    handleShow() {
      let data = {
        id: this.detail.id,
        is_show: this.detail.is_show === 1 ? 0 : 1,
      }
      this.loading = true
      updateShow(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.getPostDetail()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 修改黑名单状态
    handleBlack() {
      let data = {
        id: this.detail.id,
        is_black: this.detail.black_status === 1 ? 0 : 1,
      }
      this.loading = true
      updateBlack(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.getPostDetail()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 快速评论
    handleReply() {
      this.commentType = 'comment'
      this.replyData = {
        id: this.detail.id,
        content: '',
      }
      this.openReplyDialog = true
    },
    // 通过审核
    handleChecked() {
      this.$msgbox
        .confirm('确定要通过该帖子吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.loading = true
          check({ id: this.detail.id })
            .then((res) => {
              this.$message.success(res.msg)
              this.getPostDetail()
            })
            .catch((err) => {
              this.getPostDetail()
            })
        })
        .catch((err) => {})
    },
    // 不通过审核
    handleNoCheck() {
      this.noCheckData = {
        id: this.detail.id,
        reason: '',
      }
      this.openNoCheckedDialog = true
    },
    // 置顶帖子
    setTopRow(row) {
      if (row.is_top) {
        this.$msgbox
          .confirm('确定要取消置顶吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            updateTop({
              id: row.id,
              is_top: row.is_top ? 0 : 1,
            }).then((res) => {
              this.refreashCommentList()
            })
          })
          .catch((err) => {})
      } else {
        updateTop({
          id: row.id,
          is_top: row.is_top ? 0 : 1,
        }).then((res) => {
          this.refreashCommentList()
        })
      }
    },
    // 回复评论
    replyComment(row) {
      this.commentType = 'reply'
      this.replyData = {
        comment_id: row.id,
        content: '',
      }
      this.openReplyDialog = true
    },
    // 删除评论
    delComment(row) {
      this.$msgbox
        .confirm('确定要删除该评论吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.loading = true
          this.commentListLoading = true
          delComment({ comment_id: row.id })
            .then((res) => {
              this.$message.success(res.msg)
              this.getPostDetail()
              this.getCommentList(this.requestData)
            })
            .catch((err) => {
              this.getPostDetail()
              this.getCommentList(this.requestData)
            })
        })
        .catch((err) => {})
    },
    // 查看更多
    moreComments(row) {
      this.current_comment_id = row.id
      this.loadingMore = true
      moreComment({ comment_id: row.id })
        .then((res) => {
          row.replies = [...row.replies, ...res.data]
          this.loadingMore = false
        })
        .catch((err) => {
          this.loadingMore = false
        })
    },
    // 刷新评论列表
    refreashCommentList() {
      this.getCommentList({ ...this.requestData, page: 1 })
      this.getPostDetail()
    },
    // 返回
    cancelConfig() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-page {
  padding: 4px;
  min-height: 400px;
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-info {
      display: flex;
      .user-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      .user-content {
        margin-left: 12px;
        .user-name {
          display: flex;
          align-items: center;
          .name {
            font-size: 16px;
            color: #000000;
            line-height: 16px;
          }
          .black-tag {
            margin-left: 8px;
            background: #909090;
            border-radius: 3px;
            padding: 1px 3px;
            font-size: 12px;
            color: #ffffff;
            line-height: 14px;
          }
        }
        .post-time {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #969799;
          line-height: 12px;
          margin-top: 8px;
        }
      }
    }
    .detail-handler {
      .el-dropdown-link {
        padding: 10px 15px;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        background: #3576ff;
        border-radius: 3px;
      }
    }
  }
  .page-content {
    margin-top: 18px;
    margin-left: 48px;

    .page-desc {
      margin: 12px 0;
      font-size: 14px;
      color: #353535;
    }
    .content-number {
      margin-top: 27px;
      font-size: 14px;
      color: #353535;
      line-height: 25px;
    }
  }
  .page-list {
    margin-top: 26px;
    .level-one-comment {
      font-size: 14px;
      color: #606266;
      line-height: 14px;
    }
    .comment-handler {
      display: flex;
      align-items: center;
      margin-top: 20px;
      font-size: 14px;
      line-height: 14px;
      .comment-time {
        color: #9a9a9a;
      }
      .reply-comment {
        margin-left: 20px;
        color: #3576ff;
      }
      .del-comment {
        margin-left: 12px;
        color: #d61919;
      }
    }
    .level-two-comment {
      margin-top: 22px;
      background: #f2f3f5;
      padding: 18px;
      .two-level-comment-item + .two-level-comment-item {
        margin-top: 18px;
      }
      .load-more {
        color: #3576ff;
        font-size: 14px;
        line-height: 14px;
        margin-top: 18px;
      }
      .two-level-comment-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .comment-content {
          font-size: 14px;
          color: #606266;
          line-height: 14px;
        }
        .handler {
          .del-comment {
            font-size: 14px;
            line-height: 14px;
            color: #d61919;
          }
        }
      }
    }
  }
}
.popover-imgs {
  display: flex;
  flex-wrap: wrap;
  .popover-img {
    margin-top: 14px;
    width: 90px;
    height: 90px;
    margin-right: 16px;
    border-radius: 4px;
  }
}

.popover-video {
  position: relative;
  width: 347px;
  height: 191px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
  }
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .video-play {
      display: inline-block;
      width: 46px;
      height: 46px;
    }
  }
  ::v-deep.el-table .cell {
    padding: 0 28px;
  }
}
</style>
